<template>
  <div>
    <Header :headerIndex="3"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner3.png" alt="" />
      <div class="detail_wrap">
        <div class="product_inner max_1440">
          <div class="flex-box">
            <router-link to="/colorcard" class="g_breadcrumb">Color card</router-link>
            <div class="g_breadcrumb mlr10">></div>
            <div class="g_breadcrumb">Electronic color card details</div>
          </div>
          
          <div class="title" style="padding-left:30%;padding-right:30%;">{{colordetail.name_en}}
            <div class="btn1" @click="tocase">Viewing Cases</div>
            <div class="btn1" @click="toposter" style="right:15%;">Viewing Posters</div>
          </div>

          <img v-for="(item,index) in colordetail.images_urls" class="img_detail" :src="item" alt="" />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  components: {},
  data(){
    return {
      colorid:'',
      colorid:0,
      seriesid:0,
      colordetail:[]
    }
  },
  created(){
    this.colorid = this.$route.query.colorid
    console.log(this.colorid);
    this.getcolordetail()
  },
  methods:{
    getcolordetail(){
      var colorid = this.colorid
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Index/colordetails_2023',{cid:colorid}).then(res=>{
        // console.log(res)
        this.colordetail=res.data.info
        this.seriesid = res.data.info.typepid
        this.colorid = res.data.info.typeid
      })
    },
    tocase(){
      this.$router.push({path:'/Case',query:{typepid:this.seriesid,typeid:this.colorid}});
      // this.$router.push('/Case');
    },
    toposter(){
      this.$router.push({path:'/PosterList',query:{typepid:this.seriesid,typeid:this.colorid}});
    }
  }
};
</script>


<style scoped>
.detail_wrap {
  padding: 30px 0;
}
.detail_wrap .title {
  position: relative;
  margin: 0 0 20px;
  padding: 30px 185px 10px;
  font-size: 20px;
  line-height: 28px;
}
.detail_wrap .btn1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 185px;
  height: 70px;
  background: #cf1f1a;
  border-radius: 20px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
}
.detail_wrap .date {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .detail_wrap .title{
    padding: 30px 140px 10px;
  }
  .detail_wrap .btn1 {
    width: 139px;
    height: 53px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 53px;
  }
}
@media screen and (max-width: 1024px) {
  .detail_wrap .title{
    padding: 30px 100px 10px;
  }
  .detail_wrap .btn1 {
    width: 99px;
    height: 37px;
    background: #CF1F1A;
    border-radius: 11px;
    font-size: 10px;
    line-height: 37px;
  }
}
@media screen and (max-width: 767px) {
  .detail_wrap .title{
    padding: 30px 15px 10px;
    margin: 0 0 10px;
  }
  .detail_wrap .btn1 {
    position: static;
    margin: 10px 0 0;
    width: 100%;
  }
}
</style>
